.FileUpload__form,
.FileUpload__results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FileUpload__title {
  padding: 5%;
  text-align: center;
}

.FileUpload__input {
  margin: 5%;
  font-size: 16px;
}

.FileUpload__btn:hover {
  background-color: #f9f9f9;
  color: black;
}

.FileUpload__btn {
  padding: 10px 20px;
  margin: 30px 0;
  border: none;
  border-radius: 10px;
  background-color: #8151bf;
  color: white;
  font-size: 16px;
}
