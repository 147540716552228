.Converter {
  background-image: url("../images/full_bg.svg");
  height: calc(100vh - 94px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.Converter__input_wrapper,
.Converter__btn_wrapper {
  display: flex;
  justify-content: center;
  padding: 20px 20px 0 20px;
}

.Converter__link {
  margin: 30px auto;
  padding: 20px;
  background-color: black;
  color: white;
  text-decoration: none;
  font-size: 22px;
  border-radius: 10px;
}

.Converter__link:hover {
  background-color: #8151bf;
}

.Converter__input {
  font-size: 18px;
  padding: 10px 20px;
  width: 50%;
  border: 0;
  border-radius: 5px;
}

.Converter__find_btn {
  font-size: 18px;
  padding: 25px;
  margin: 0 20px;
  border: 0;
  border-radius: 5px;
  background: #8151bf url("../images/search.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
}

.Converter__find_btn:hover {
  color: white;
  background-color: #1f1f1f;
}

.Converter__vid_title {
  padding: 0 10%;
  font-size: 20px;
  margin: 20px;
  color: white;
  font-weight: normal;
  text-align: center;
}

.Converter__video {
  width: 700px;
  height: 400px;
  border: none;
}
.Converter__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.Converter__download {
  text-decoration: none;
  font-size: 18px;
  border: 1px solid black;
  background-color: black;
  padding: 10px 20px;
  margin: 20px;
  border-radius: 5px;
  color: white;
}

.Converter__download:hover {
  background-color: white;
  color: black;
}

.Converter__title {
  margin: 0;
  padding: 5% 20%;
  line-height: 65px;
}

.Converter__header {
  align-self: stretch;
}
