.SearchResults {
  padding-bottom: 50px;
}

.SearchResults__title {
  margin: 0;
  padding: 10px;
  font-size: 22px;
}

.SearchResults__result {
  padding: 10px;
  margin: 20px 20px 0 20px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 2px solid white;
}

.SearchResults__result:last-child {
  border: none;
}

.SearchResults__container {
  display: flex;
  align-items: baseline;
}

.SearchResults__iframe {
  width: 350px;
  height: 175px;
  border: 0;
}

.SearchResults__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SearchResults__result_title {
  padding: 20px 50px;
}

.SearchResults__back {
  margin: 20px 50px;
  padding: 5px 10px;
  font-size: 24px;
  color: white;
  text-decoration: none;
  border: 1px solid white;
  border-radius: 5px;
}

.SearchResults__back:hover {
  background-color: black;
  border: 1px solid black;
}

.purple {
  background-color: #8151bf;
  border: 0;
}

.dropbtn {
  background-color: #f9f9f9;
  border-radius: 5px;
  color: black;
  padding: 20px 30px;
  margin: 20px 0 0 0;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #dddddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #8151bf;
  color: white;
  border-radius: 5px 5px 0 0;
}
